$max-width: 1100px;

// Standard height options
$panel-height-sm: 28px;
$panel-height: 40px;
$panel-height-lg: 60px;

// Border radius
$border-radius: 8px;
$border-radius-sm: 4px;

// Font size
$base-font-size: 16px; // 1rem
$font-size-sm: 14px;
$font-size-xs: 12px;
$font-size-xxs: 11px;

// Line height
$base-line-height: 20px;

// Font family
$base-font-family: Inter, Arial, sans-serif;
$code-font-family: Menlo, monospace;

// Icon size
$icon-size: 2rem;
$icon-size-sm: 1.5rem;
