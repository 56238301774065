@use 'mixins';
@use 'partials/variables' as var;

body {
  background: var(--rosetta-color-bg);

  a {
    color: var(--rosetta-text-color);
    @include mixins.transitionFast(color);

    &:hover {
      color: var(--rosetta-text-color-primary);
    }
  }

  :focus-visible {
    outline-color: var(--rosetta-brand-color-primary);
  }

  [contenteditable='true'] {
    pointer-events: stroke;

    &:focus-visible {
      outline: 1px solid var(--rosetta-brand-color-primary);
      background-color: var(--rosetta-color-bg-alt);
    }
  }

  .warn-bg {
    background: var(--rosetta-color-bg-warn);
    color: var(--rosetta-text-color-warn-contrast);
  }

  .theme {
    &-color {
      color: var(--rosetta-text-color);

      &-fill {
        fill: var(--rosetta-text-color);
      }

      &-hint {
        color: var(--rosetta-text-color-alt);
      }

      &-warn {
        color: var(--rosetta-text-color-warn) !important;
      }

      &-pending {
        color: var(--rosetta-text-color-pending);
      }

      &-primary {
        color: var(--rosetta-text-color-primary);
      }

      &-primary-alt {
        color: var(--rosetta-text-color-primary-alt);
      }

      &-accent {
        color: var(--rosetta-text-color-accent);
      }

      &-highlight {
        color: var(--rosetta-text-color-highlight);
      }

      &-success {
        color: var(--rosetta-text-color-success) !important;
      }
    }

    &-bg {
      background: var(--rosetta-color-bg);
      color: var(--rosetta-text-color);

      &-none {
        background: none;
      }

      &-primary {
        background-color: var(--rosetta-color-bg-primary);
        color: var(--rosetta-text-color-primary-contrast) !important;
      }

      &-primary-50 {
        background-color: var(--rosetta-color-bg-primary-50);
        color: var(--rosetta-text-color-primary-50-contrast) !important;
      }

      &-alt {
        background: var(--rosetta-color-bg-alt);
        color: var(--rosetta-text-color);
      }

      &-light {
        background: var(--rosetta-color-bg-light-active);
      }

      &-lighter {
        background: var(--rosetta-color-bg-light);
      }

      &-lighter-hover:hover {
        background: var(--rosetta-color-bg-light-hover);
      }

      &-color {
        background: var(--rosetta-color-bg-primary);
        color: var(--rosetta-text-color-primary-contrast);
      }

      &-success {
        background: var(--rosetta-color-bg-success) !important;
      }

      &-warn {
        background: var(--rosetta-color-bg-warn) !important;
      }
    }

    &-border {
      border: 1px solid var(--rosetta-border-color);

      &-top {
        border-top: 1px solid var(--rosetta-border-color);
      }

      &-right {
        border-right: 1px solid var(--rosetta-border-color);
      }

      &-bottom {
        border-bottom: 1px solid var(--rosetta-border-color);
      }

      &-left {
        border-left: 1px solid var(--rosetta-border-color);
      }

      &-dark {
        border: 1px solid var(--rosetta-border-color-dark);
      }

      &-top-light {
        border-top: 1px solid var(--rosetta-border-color-light-active);
      }

      &-light {
        border: 1px solid var(--rosetta-border-color-light);
      }

      &-radius {
        border-radius: var.$border-radius;
      }

      &-radius-sm {
        border-radius: var.$border-radius-sm;
      }
    }

    &-fa-icon {
      svg path {
        color: var(--rosetta-text-color-alt);
      }

      &-accent svg path {
        color: var(--rosetta-text-color-accent);
      }
    }
  }
}
