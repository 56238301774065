.font-uppercase {
  text-transform: uppercase;
}

@mixin link-text-color() {
  color: var(--rosetta-text-color-primary);

  &:hover {
    color: var(--rosetta-text-color-primary-hover);
  }
}

.link-text-color {
  @include link-text-color();
}
